<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item :label="$t('searchForm.organ')">
              <el-input
                v-model="searchFrom.organizationName"
                :clearable="true"
                :placeholder="$t('searchForm.organPlaceholder')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchForm.organCode')">
              <el-input
                v-model="searchFrom.organizationCode"
                :clearable="true"
                :placeholder="$t('searchForm.organCodePlaceholder')"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('searchForm.status')">
              <el-select v-model="searchFrom.organizationStatus">
                <el-option :label="$t('searchForm.all')" value="" />
                <el-option :label="$t('enum.status[0]')" value="0" />
                <el-option :label="$t('enum.status[1]')" value="1" />
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="search">{{ $t('btn.search') }}</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="add">{{ $t('btn.add') }}</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <div class="table-block">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f9fc', border: 'none', color: '#252526' }"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" :label="$t('table.order')" width="100" align="center" />
        <el-table-column prop="organizationCode" :label="$t('table.organCode')" align="center" />
        <el-table-column prop="organizationName" :label="$t('table.organ')" align="center" />
        <el-table-column prop="parentOrganizationCode" :label="$t('table.parentOrganizationCode')" align="center" />
        <!-- <el-table-column prop="parentOrgan" :label="$t('table.parentOrgan')" align="center" /> -->
        <el-table-column prop="organizationStatus" :label="$t('table.status')" align="center" width="100px">
          <template slot-scope="scope">
            <div :class="[scope.row.organizationStatus == 1 ? 'status-point-normal' : 'status-point-freeze']">
              {{ $t(`enum.status[${scope.row.organizationStatus}]`) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('table.createTime')" align="center" />
        <el-table-column prop="createByName" :label="$t('table.createBy')" align="center" />
        <el-table-column :label="$t('table.operate')" align="center" width="100px">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="edit(scope.$index)">{{ $t('btn.edit') }}</el-link>
            <el-divider direction="vertical" />
            <el-link :underline="false" type="danger" @click="del(scope.row.organizationCode)">{{
              $t('btn.del')
            }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @changePage="changePage"
        :total="total"
        :page.sync="searchFrom.pageNo"
        :size.sync="searchFrom.pageSize"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      :show-close="false"
      class="page-dialog"
      :close-on-click-modal="false"
      @close="onClose"
    >
      <template slot="title">
        <h3 class="dialog-title">{{ isEdit ? $t('btn.edit') : $t('btn.add') }}</h3>
      </template>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-width="$i18n.locale === 'en' ? '155px' : '80px'"
        class="page-dialog-form"
      >
        <el-form-item :label="$t('dialog.organManagement.organCode')" prop="organizationCode">
          <el-input
            v-model="form.organizationCode"
            :placeholder="$t('dialog.organManagement.organCodeMsg')"
            :clearable="true"
            :disabled="isEdit"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.organManagement.organName')" prop="organizationName">
          <el-input
            v-model="form.organizationName"
            :placeholder="$t('dialog.organManagement.organNameMsg')"
            :clearable="true"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('dialog.organManagement.parentOrganCode')" prop="parentOrganizationCode">
          <organzition-tree :defaultCheckKey.sync="defaultCheckKey" :treeData="treeData" @checkChange="checkChange" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('btn.cancel') }}</el-button>
        <el-button type="primary" @click="onConfirm" :loading="loadingBtn">{{ $t('btn.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { organList, organTree, organSave, organDel } from 'src/api/index'
import { convertToFrontByCode } from 'src/utils/utils'
import pagination from 'src/components/pagination'
import organzitionTree from 'src/components/organzitionTree.vue'

export default {
  name: 'organ-management',
  components: { pagination, organzitionTree },
  data() {
    const parentOrganCodeValidate = (rule, value, callback) => {
      if (!this.form.parentOrganizationCode) {
        callback(new Error(this.$t('dialog.organManagement.parentOrganCodeMsg')))
      } else {
        callback()
      }
    }
    return {
      searchFrom: {
        organizationName: '',
        organizationStatus: '',
        organizationCode: '',
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      tableData: [],
      total: 0,
      dialogVisible: false,
      isEdit: false,
      form: {
        organizationCode: '',
        organizationName: '',
        parentOrganizationCode: '',
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      defaultCheckKey: [], //tree初始化选中项
      loadingBtn: false,
      parentOrganCodeValidate,
    }
  },
  watch: {},
  computed: {
    rules() {
      return {
        parentOrganizationCode: [
          {
            required: true,
            validator: this.parentOrganCodeValidate,
            trigger: 'blur',
          },
        ],
        organizationCode: [
          { required: true, message: this.$t('dialog.organManagement.organCodeMsg'), trigger: 'blur' },
        ],
        organizationName: [
          { required: true, message: this.$t('dialog.organManagement.organNameMsg'), trigger: 'blur' },
        ],
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      organList(this.searchFrom).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      })
    },

    async add() {
      await this.getOrganTree()
      this.dialogVisible = true
    },

    getOrganTree() {
      organTree().then(res => {
        if (!this.isEdit) {
          this.defaultCheckKey = [res[0]['organizationCode']]
          this.form['parentOrganizationCode'] = res[0]['organizationCode']
        }
        this.treeData = convertToFrontByCode(res)
      })
    },

    changePage(pageParams) {
      const { pageNo, pageSize } = pageParams
      this.searchFrom = { ...this.searchFrom, pageNo, pageSize }
      this.getList()
    },
    onClose() {
      this.$refs.form.resetFields()
      this.loadingBtn = false
      this.isEdit = false
      this.treeData = []
      this.form = {
        organizationCode: '',
        organizationName: '',
        parentOrganizationCode: '',
      }
    },
    onConfirm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingBtn = true
          organSave(this.form)
            .then(res => {
              this.dialogVisible = false
              this.loadingBtn = false
              this.isEdit = false
              this.$message({
                message: this.$t('message.saveSuccess'),
                type: 'success',
              })
              this.getList()
            })
            .catch(err => {
              this.loadingBtn = false
            })
        } else {
          return false
        }
      })
    },

    search() {
      this.searchFrom = { ...this.searchFrom, pageNo: 1 }
      this.getList()
    },

    edit(index) {
      this.isEdit = true
      const { organizationId, parentOrganizationCode, organizationCode, organizationName } = this.tableData[index]
      this.getOrganTree()
      this.defaultCheckKey = [parentOrganizationCode]
      this.form = { organizationId, parentOrganizationCode, organizationCode, organizationName }
      this.dialogVisible = true
    },

    checkChange(parentOrganObj) {
      this.form = { ...this.form, parentOrganizationCode: parentOrganObj['id'] }
    },

    del(organizationCode) {
      this.$confirm(this.$t('message.delTip'), this.$t('message.warning'), {
        confirmButtonText: this.$t('btn.confirm'),
        cancelButtonText: this.$t('btn.cancel'),
        type: 'warning',
      })
        .then(() => {
          organDel({ organizationCode }).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.delSuccess'),
            })
            this.getList()
          })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
</style>
